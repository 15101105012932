import { SubsequentActionType } from '@celum/work/app/core/model/subsequent-action.model';
import { ProgressTaskParams } from '@celum/work/app/progress-task/store/progress-task.model';

import { File as FileEntity } from '../../../core/model/entities/file/file.model';
import { Task } from '../../../core/model/entities/task';
import { Workroom } from '../../../core/model/entities/workroom';

export interface UploadParams {
  contentItemId?: string;
  file: File;
  folderId?: string;
  isNewVersion: boolean;
  workroom?: Workroom;
  taskId?: number;
  action?: SubsequentActionType;
  targetEntityForCommentBasedFiles?: FileEntity | Task;
}

export class UploadProgressTaskParams implements UploadParams, ProgressTaskParams {
  public contentItemId?: string;
  public file: File;
  public folderId?: string;
  public isNewVersion: boolean;
  public workroom?: Workroom;
  public taskId?: number;
  public quillImageUid?: string;
  public portalId?: string;
  public action?: SubsequentActionType;
  public targetEntityForCommentBasedFiles?: FileEntity | Task;

  constructor(uploadParams: UploadParams) {
    Object.assign(this, uploadParams);
  }

  public getTitle(): string {
    return this.file.name;
  }
}
