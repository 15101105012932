import { Observable, of } from 'rxjs';

import { appendUrlSearchParam } from '@celum/core';

import { AuthInterceptorConfig, ServiceTokenInterceptorConfig } from '../auth.interceptor';
import { ServiceAccessTokenProvider } from '../services/service-access-token-provider.service';

/**
 * Get the authentication token which should be used for the service the passed url is pointing to.
 * Returns `null` in case there is no configuration available.
 *
 * @param url                           the url to get the token for
 * @param interceptorConfig             the interceptor configs for the "supported" services
 * @param serviceAccessTokenProvider    the service to retrieve the token from
 * @param tokenLeadTimeInMilliseconds   the duration in milliseconds before token expiration before it is refreshed
 */
export function getAuthToken(
  url: string,
  interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>,
  serviceAccessTokenProvider: ServiceAccessTokenProvider,
  tokenLeadTimeInMilliseconds?: number
): Observable<string> {
  const apiConfigurations = interceptorConfig.getInterceptorConfiguration().apiConfigurations;
  const dto = apiConfigurations.find(config => config.apiUrls.find(apiUrl => url.startsWith(apiUrl)));

  // Calls to services get the according service access token attached
  if (dto) {
    return serviceAccessTokenProvider.getServiceAccessToken(dto.serviceTokenRequestDto, tokenLeadTimeInMilliseconds);
  }

  return of(null);
}

export function appendTokenToUrl(url: string, token: string, replace = true): string {
  if (!url) {
    return url;
  }

  // null means no token could be obtained. Undefined is currently reserved for unprotected portals, which do not require tokens.
  if (token === null) {
    console.debug(`AuthTokenUtil: no token returned for url '${url}'.`);
  }

  if (!token) {
    return url;
  }

  return appendUrlSearchParam(url, 'token', token, replace);
}
