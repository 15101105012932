import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import produce from 'immer';

import { DataUtil } from '@celum/core';
import { EntityUtil } from '@celum/work/app/core/model';
import { mergeEntities, mergeEntity } from '@celum/work/app/core/model/entities/entities-state-util';
import {
  ContentItemContentItemsDeleted,
  ContentItemContentItemsRestored
} from '@celum/work/app/files/store/content-item-list/content-item-list.actions';

import {
  FileAddContentHubConnection,
  FileDeleteMany,
  FileDeleteOne,
  FileFetchLinkedPortalAssetIdsSucceeded,
  FileFetchTaskCountsSucceeded,
  FileUpdatePortalAssetLinkedState,
  FileUpsertMany,
  FileUpsertOne
} from './file.actions';
import { File, FileState } from './file.model';
import { TaskDeleteTasksSucceeded } from '../../../api/task/task.actions';
import { TaskListDeleteTaskListSucceeded } from '../../../api/task-list/task-list.actions';

export const fileAdapter: EntityAdapter<File> = createEntityAdapter<File>();
export const initialState: FileState = fileAdapter.getInitialState();

export const fileProperties = ['name', 'workroomId', 'parentId', 'activeVersionId', 'activeVersion', 'numberOfTasks'];
export const fileUpdatableProperties = ['name', 'workroomId', 'parentId', 'activeVersionId', 'activeVersion'];

const reducer = createReducer(
  initialState,
  on(FileUpsertOne, (state: FileState, { file, propertiesToUpdate }) => {
    const files = EntityUtil.changedEntities(fileProperties, [file], state.entities);

    if (!DataUtil.isEmpty(files)) {
      return fileAdapter.upsertOne(mergeEntity(files[0], state, propertiesToUpdate), state);
    } else {
      return state;
    }
  }),

  on(FileUpsertMany, (state: FileState, { files, propertiesToUpdate }) => {
    const updatedFiles = EntityUtil.changedEntities(fileProperties, files, state.entities);
    return fileAdapter.upsertMany(mergeEntities(updatedFiles, state, propertiesToUpdate), state);
  }),

  on(FileDeleteOne, (state: FileState, { id }) => {
    return fileAdapter.removeOne(id, state);
  }),

  on(FileDeleteMany, (state: FileState, { ids }) => {
    return fileAdapter.removeMany(ids, state);
  }),

  on(ContentItemContentItemsDeleted, ContentItemContentItemsRestored, (state: FileState, { contentItemIds }) => {
    return fileAdapter.removeMany(contentItemIds, state);
  }),

  on(FileAddContentHubConnection, (state: FileState, { id, contentHubConnection }) =>
    produce(state, draft => {
      const file = draft.entities[id];
      if (DataUtil.isEmpty(file.contentHubConnections)) {
        file.contentHubConnections = [contentHubConnection];
      } else {
        file.contentHubConnections.push(contentHubConnection);
      }
    })
  ),

  on(FileFetchTaskCountsSucceeded, (state: FileState, { fileTaskCounts }) =>
    produce(state, draft => {
      fileTaskCounts.forEach(fileTaskCount => {
        const file = draft.entities[fileTaskCount.fileId];
        if (file) {
          file.numberOfTasks = fileTaskCount.numberOfTasks;
        }
      });
    })
  ),

  on(FileFetchLinkedPortalAssetIdsSucceeded, (state: FileState, { linkedPortalAssetIds }) =>
    produce(state, draft => {
      linkedPortalAssetIds.forEach(fileId => {
        const file = draft.entities[fileId];
        if (file) {
          file.hasLinkedPortalAssets = true;
        }
      });
    })
  ),

  on(TaskDeleteTasksSucceeded, TaskListDeleteTaskListSucceeded, (state: FileState, { tasks }) =>
    produce(state, draft => {
      const fileIds = tasks.map(task => task.attachmentIds).flat();
      fileIds.forEach(fileId => {
        const file = draft.entities[fileId];
        if (file) {
          file.hasLinkedPortalAssets = false;
        }
      });
    })
  ),

  on(FileUpdatePortalAssetLinkedState, (state: FileState, { fileId, hasLinkedPortalAssets }) =>
    produce(state, draft => {
      const file = draft.entities[fileId];
      if (file) {
        file.hasLinkedPortalAssets = hasLinkedPortalAssets;
      }
    })
  )
);

export function fileReducer(state: FileState = initialState, action: Action): FileState {
  return reducer(state, action);
}
