import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDialogOpener, CelumSimpleList } from '@celum/internal-components';
import { selectCanImport } from '@celum/work/app/content-hub/store/content-hub.selectors';
import { File } from '@celum/work/app/core/model/entities/file/file.model';
import { ContentItemPermanentlyDeleteDialogOpener } from '@celum/work/app/pages/workroom/pages/files/services/content-item-permanently-delete-dialog-opener';
import { RobotScopeResolverService } from '@celum/work/app/robots/services/robot-scope-resolver.service';
import { selectAutomators } from '@celum/work/app/robots/store/robot.selectors';

import { ContentHubBuildNumberStrategyResolverService } from '../../../../content-hub/services/content-hub-build-number-strategy-resolver.service';
import { ContentHubImportActions } from '../../../../content-hub/store/import.actions';
import { ContentItem } from '../../../../core/model/entities/content-item/content-item.model';
import { Folder, FolderType } from '../../../../core/model/entities/folder/folder.model';
import {
  CreateFolderDialog,
  CreateFolderDialogConfiguration
} from '../../../../pages/workroom/pages/files/components/create-folder-dialog/create-folder-dialog.component';
import {
  DeleteItemsDialog,
  DeleteItemsDialogConfiguration
} from '../../../../pages/workroom/pages/files/components/delete-items-dialog/delete-items-dialog.component';
import {
  ContentItemRenameDialog,
  ContentItemRenameDialogConfiguration
} from '../../../../pages/workroom/pages/files/components/rename-item/rename-dialog.component';
import { ShareService } from '../../../services/share.service';
import { ContentItemBase } from '../content-item';

@Component({
  selector: 'folder-card',
  templateUrl: './folder-card.component.html',
  styleUrls: ['../content-item.shared.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FolderCardComponent extends ContentItemBase<Folder> implements OnInit {
  @Input() public showToolbar: boolean;

  @Output() public readonly contentItemsRemoved: EventEmitter<Folder[]> = new EventEmitter<Folder[]>();

  public readonly createFolderIcon = IconConfiguration.small('create-folder-s').withColor(ColorConstants.BLUE_GRAY_900);
  public readonly uploadNewFileIcon = IconConfiguration.small('upload-l').withColor(ColorConstants.BLUE_GRAY_900);
  public readonly importNewFileFromCHIcon = IconConfiguration.small('import').withColor(ColorConstants.BLUE_GRAY_900);
  public readonly folderIcon = IconConfiguration.large('folder').withIconSize(125);
  public readonly renameIconSmall = IconConfiguration.small('edit-l');
  public readonly createShareIcon = IconConfiguration.small('share-l');
  public readonly folderType = FolderType.TYPE_KEY;

  public canCreateNewFolder$: Observable<boolean>;
  public canDeleteFolder$: Observable<boolean>;
  public canRenameFolder$: Observable<boolean>;
  public canCreateNewFile$: Observable<boolean>;
  public canImportFromCH$: Observable<boolean>;
  public hasPortalAssetsInSelection$: Observable<boolean>;

  constructor(
    private renderer: Renderer2,
    protected celumDialogOpener: CelumDialogOpener,
    public store: Store<any>,
    public celumSimpleList: CelumSimpleList<Folder>,
    public translateService: TranslateService,
    public contentItemPermanentlyDeleteDialogOpener: ContentItemPermanentlyDeleteDialogOpener,
    public chStrategyResolver: ContentHubBuildNumberStrategyResolverService,
    public shareService: ShareService,
    private robotScopeResolver: RobotScopeResolverService,
    @Inject(DOCUMENT) public document: Document
  ) {
    super(
      celumDialogOpener,
      celumSimpleList,
      store,
      translateService,
      contentItemPermanentlyDeleteDialogOpener,
      chStrategyResolver,
      shareService,
      document
    );
  }

  public ngOnInit(): void {
    this.canCreateNewFolder$ = this.lastSelection$.pipe(map(selection => selection.length <= 1));
    this.canDeleteFolder$ = this.lastSelection$.pipe(
      map(selection => !!selection && !super.selectionHasPortalAssets())
    );
    this.canRenameFolder$ = this.lastSelection$.pipe(map(selection => selection.length <= 1));
    this.canCreateNewFile$ = this.lastSelection$.pipe(map(selection => selection.length <= 1));

    this.canImportFromCH$ = this.store.select(selectCanImport).pipe(
      withLatestFrom(this.chStrategyResolver.resolve()),
      map(([canImport, strategy]) => canImport && this.getSelectionLength() <= 1 && strategy.supportsRelayCalls())
    );

    this.hasPortalAssetsInSelection$ = this.lastSelection$.pipe(
      map(selection => selection.some(item => (item as unknown as File).hasLinkedPortalAssets))
    );
  }

  public async handleContextmenu(event: MouseEvent, el: HTMLSpanElement, contentItem: Folder): Promise<void> {
    event.preventDefault();

    if (!super.checkCanRightClick(contentItem)) {
      return;
    }

    this.renderer.setStyle(el, 'left', `${event.offsetX}px`);
    this.renderer.setStyle(el, 'top', `${event.offsetY}px`);
    // Need to set openedBy explicitly, otherwise it will focus first menu item
    this.matMenuTrigger._openedBy = 'mouse';
    this.matMenuTrigger.openMenu();
  }

  public deleteFolder(item: ContentItem): void {
    this.celumDialogOpener.showDialog(
      DeleteItemsDialog.name,
      DeleteItemsDialog,
      new DeleteItemsDialogConfiguration(this.getSelection()?.length > 0 ? this.getSelection() : [item])
    );
  }

  public createNewFolder(parentFolder: Folder) {
    this.celumDialogOpener.showDialog(
      CreateFolderDialog.name,
      CreateFolderDialog,
      new CreateFolderDialogConfiguration(parentFolder)
    );
  }

  public renameFolder(folder: Folder): void {
    this.celumDialogOpener.showDialog(
      ContentItemRenameDialog.name,
      ContentItemRenameDialog,
      new ContentItemRenameDialogConfiguration(folder)
    );
  }

  public folderHasAppliedAutomators(folder: Folder): Observable<boolean> {
    return this.store
      .select(selectAutomators)
      .pipe(map(automators => this.robotScopeResolver.filterRobotsBelongingToFolder(automators, folder).length > 0));
  }

  public importNewFileFromCH(): void {
    this.store.dispatch(ContentHubImportActions.RequestImportAsNewFile({ parentId: this.contentItem.id }));
  }
}
