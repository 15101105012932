import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';

import {
  CelumDialog,
  CelumDialogConfiguration,
  ColorConstants,
  EmptyPage,
  EmptyPageConfig,
  IconConfiguration
} from '@celum/common-components';
import { isTruthy, PaginationResult } from '@celum/core';
import { ImportIntentSelectFolderService } from '@celum/work/app/content-hub/services/import-intent-select-folder.service';
import { TaskListService } from '@celum/work/app/core/api/task-list/task-list.service';
import { TaskList } from '@celum/work/app/core/model/entities/task/task-list.model';

import { ImportIntent } from '../../../../../content-hub/model/import-intent.model';
import { RoleName } from '../../../../../core/api/permission';
import { Roles } from '../../../../../core/model';
import {
  selectAllTeamspaces,
  selectHasRoleInAnyTeamspace,
  Teamspace
} from '../../../../../core/model/entities/teamspace';
import { Workroom } from '../../../../../core/model/entities/workroom';
import { selectLoggedInPerson, selectTenantTeamspace } from '../../../../../core/ui-state/ui-state.selectors';
import { PermissionUtil } from '../../../../../shared/util';
import { TemplateChooserDialogConfiguration } from '../../../../workroom-creator/components/template-chooser-dialog/template-chooser-dialog.component';
import * as WorkroomCreatorActions from '../../../../workroom-creator/store/workroom-creator.actions';
import { WorkroomStore } from '../store/workroom.store';

@Component({
  selector: 'import-to-workrooms-dialog',
  templateUrl: './import-to-workrooms-dialog.component.html',
  styleUrls: ['./import-to-workrooms-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [WorkroomStore]
})
export class ImportToWorkroomsDialogComponent implements OnInit, CelumDialog<TemplateChooserDialogConfiguration> {
  public static readonly DIALOG_ID = 'import-to-workrooms-dialog';

  public color: string;
  public teamspace$: Observable<Teamspace>;
  public paginationResult$: Observable<PaginationResult>;
  public selectedWorkroom: Workroom;
  public queryString: string;
  public importIntent: ImportIntent;
  public portalId: string;
  public createDefaultWorkroomText: string;
  public createWorkroomFromTemplateText: string;

  public readonly emptyPageConfig: EmptyPageConfig;
  public readonly noResultsConfig: EmptyPageConfig;
  public readonly emptyPageAndNoPermissionToCreateWorkroom: EmptyPageConfig;
  public readonly emptyPageWithNoPermissionToCreateFromPortal: EmptyPageConfig;
  public readonly infoIconSmall = IconConfiguration.small('info-icon');
  public readonly cancelIcon: IconConfiguration = IconConfiguration.medium('cancel-s').withColor('white');
  public readonly submitIcon: IconConfiguration = IconConfiguration.medium('export').withColor('white');

  constructor(
    private dialogRef: MatDialogRef<ImportToWorkroomsDialogComponent>,
    private store: Store<any>,
    public workroomStore: WorkroomStore,
    private router: Router,
    private permissionUtil: PermissionUtil,
    private taskListService: TaskListService,
    private importIntentSelectFolderService: ImportIntentSelectFolderService
  ) {
    this.emptyPageConfig = EmptyPage.noActionConfig(
      'no-workrooms',
      'no-workrooms',
      'IMPORT_TO_WORKROOMS.DIALOG.WORKROOMS.EMPTY',
      'small',
      159
    );
    this.emptyPageAndNoPermissionToCreateWorkroom = EmptyPage.noActionConfig(
      'no-workrooms',
      'no-workrooms',
      'IMPORT_TO_WORKROOMS.DIALOG.WORKROOMS.EMPTY_OR_NO_PERMISSION_TO_CREATE_WORKROOM',
      'small',
      159
    );
    this.emptyPageWithNoPermissionToCreateFromPortal = EmptyPage.noActionConfig(
      'no-results',
      'no-workrooms',
      'IMPORT_TO_WORKROOMS.DIALOG.WORKROOMS.EMPTY_AND_NO_PRIVILEGE',
      'small',
      159
    );
    this.noResultsConfig = EmptyPage.noActionConfig(
      'no-results',
      'filter-no-workroom-result',
      'IMPORT_TO_WORKROOMS.DIALOG.WORKROOMS.NO_RESULTS',
      'small',
      159
    );
  }

  public get hasWorkroomCreatorRole$(): Observable<boolean> {
    return this.store.select(selectHasRoleInAnyTeamspace(RoleName.WORKROOM_CREATOR));
  }

  public get isTeamspacePermissionsLoaded(): Observable<boolean> {
    return this.store
      .select(selectAllTeamspaces)
      .pipe(map(teamspaces => teamspaces?.some(teamspace => !!teamspace.permissions)));
  }

  public get showEmptyPage$(): Observable<EmptyPageConfig> {
    if (this.queryString) {
      return of(this.noResultsConfig);
    }
    return this.store.select(selectHasRoleInAnyTeamspace(RoleName.WORKROOM_CREATOR)).pipe(
      map(hasWorkroomCreateRole => {
        return hasWorkroomCreateRole
          ? this.emptyPageConfig
          : this.portalId
            ? this.emptyPageWithNoPermissionToCreateFromPortal
            : this.emptyPageAndNoPermissionToCreateWorkroom;
      })
    );
  }

  public get hasMore$(): Observable<boolean> {
    return combineLatest([this.paginationResult$, this.workroomStore.loading$]).pipe(
      map(([paginationResult, isLoading]) => !isLoading && paginationResult?.hasBottom)
    );
  }

  public get workrooms$(): Observable<Workroom[]> {
    return this.workroomStore.selectWorkrooms();
  }

  public get existingWorkroomsInfoAndSearchEnabled(): Observable<boolean> {
    return combineLatest([this.paginationResult$, this.hasWorkroomCreatorRole$]).pipe(
      map(
        ([paginationResult, hasWorkroomCreatorRole]) =>
          paginationResult.totalElementCount > 0 ||
          (paginationResult.totalElementCount === 0 && hasWorkroomCreatorRole) ||
          (paginationResult.totalElementCount === 0 && !!this.queryString)
      )
    );
  }

  public ngOnInit(): void {
    this.teamspace$ = this.store.select(selectTenantTeamspace);
    this.paginationResult$ = this.workroomStore.paginationResult$;

    this.teamspace$
      .pipe(map(teamspace => !!teamspace))
      .pipe(isTruthy(), take(1))
      .subscribe(() => {
        this.workroomStore.loadWorkrooms({
          queryString: '',
          repositoryId: this.importIntent?.repositoryId,
          offset: 0
        });
      });
  }

  public configure({ color, importIntent, portalId }: ImportToWorkroomsDialogConfiguration): void {
    this.color = color;
    this.importIntent = importIntent;
    this.portalId = portalId;

    if (this.shouldShowDefaultWorkroomButtonText(importIntent, portalId)) {
      this.setWorkroomButtonText(
        'IMPORT_TO_WORKROOMS.DIALOG.NEW_BUTTON.CREATE_DEFAULT_WORKROOM',
        'IMPORT_TO_WORKROOMS.DIALOG.NEW_BUTTON.CREATE_WORKROOM_FROM_TEMPLATE'
      );
    } else {
      this.setWorkroomButtonText(
        'IMPORT_TO_WORKROOMS.DIALOG.NEW_BUTTON.CREATE_DEFAULT_WORKROOM_AND_WATCH_COLLECTION',
        'IMPORT_TO_WORKROOMS.DIALOG.NEW_BUTTON.CREATE_WORKROOM_FROM_TEMPLATE_AND_WATCH_COLLECTION'
      );
    }
  }

  public async closeDialog() {
    this.store.dispatch(WorkroomCreatorActions.WorkroomCreatorResetImportIntentInformation());
    this.dialogRef.close();
    this.router.navigate([`dashboard`]);
  }

  public onValueChanged(queryString: string): void {
    this.queryString = queryString;

    this.workroomStore.loadWorkrooms({
      queryString,
      repositoryId: this.importIntent?.repositoryId,
      offset: 0
    });
  }

  public onRequestNextPage(): void {
    this.workroomStore.fetchNextWorkroomsBatch(this.queryString, this.importIntent?.repositoryId);
  }

  public trackByFn(_: number, item: Workroom): number {
    return item.id;
  }

  public workroomSelected(workroom: Workroom): void {
    this.selectedWorkroom = workroom;
  }

  public onConfirmClick(): void {
    if (this.portalId) {
      this.navigateToTasksOverview();
    } else {
      this.navigateToFilesOverview();
    }
  }

  public navigateToTasksOverview(): void {
    this.closeDialog();

    this.router.navigate([`workroom/${this.selectedWorkroom.id}/tasks`], {
      state: {
        portalId: this.portalId
      }
    });
  }

  public navigateToFilesOverview(): void {
    this.closeDialog();

    of(this.importIntent)
      .pipe(
        mergeMap(({ collectionCounter }) =>
          collectionCounter > 0
            ? this.hasModeratorRoleForSelectedWorkroom()
            : this.taskListService.loadTaskLists(this.selectedWorkroom.id)
        ),
        take(1)
      )
      .subscribe(value => {
        const subMenuItems =
          this.importIntent.collectionCounter > 0
            ? this.importIntentSelectFolderService.getSubMenuItemsForCollectionImport(value as boolean)
            : this.importIntentSelectFolderService.getSubMenuItemsForAssetImport(
                this.selectedWorkroom.configuration,
                value as TaskList[]
              );

        this.router.navigate([`workroom/${this.selectedWorkroom.id}/assets`], {
          state: {
            shouldOpenAddToFolderDialog: true,
            subMenuItems,
            importIntent: this.importIntent
          }
        });
      });
  }

  private shouldShowDefaultWorkroomButtonText(importIntent: ImportIntent | null, portalId?: string): boolean {
    return !!portalId || (importIntent !== null && importIntent.collectionCounter === 0);
  }

  private setWorkroomButtonText(defaultButtonText: string, templateButtonText: string): void {
    this.createDefaultWorkroomText = defaultButtonText;
    this.createWorkroomFromTemplateText = templateButtonText;
  }

  private hasModeratorRoleForSelectedWorkroom(): Observable<boolean> {
    if (!this.selectedWorkroom) {
      return of(false);
    }

    return this.store.select(selectLoggedInPerson).pipe(
      take(1),
      switchMap(person => {
        return this.permissionUtil.hasWorkroomRole(Roles.MODERATOR, person, this.selectedWorkroom);
      })
    );
  }
}

export class ImportToWorkroomsDialogConfiguration extends CelumDialogConfiguration {
  constructor(
    public importIntent: ImportIntent | null = null,
    public portalId?: string,
    public color: string = ColorConstants.PRIMARY
  ) {
    super('main');
  }
}
